import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const casaDownloadQrSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    template: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .min(1).max(2)
      .integer().nullable().label(i18n.t('app_users:temp_login.casa.template')),
    date: yup.date().nullable().required().label(i18n.t('app_users:temp_login.casa.date')),
    title: yup.string().nullable().required().label(i18n.t('app_users:temp_login.casa.title')),
    content: yup.string().nullable().required().label(i18n.t('app_users:temp_login.casa.content')),
    deadline: yup.date().nullable().required().label(i18n.t('app_users:temp_login.casa.deadline'))
  })
}
