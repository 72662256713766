import { api, DEFAULT_QUERY_LIMIT, getFileFromURL, MULTIPART_HEADERS } from '@gk-devteam/apmc-core-web'
import { CancelToken } from 'axios'
import { saveAs } from 'file-saver'

export const fetchAppUsers = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }
  return api.get('app_users', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token,
    headers: {
      responseType: params.csv ? 'blob' : 'application/json'
    }
  })
}

export const fetchAppUser = (id, cancelSource) => {
  return api.get(`app_users/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const postAppUser = (data, userID, cancelSource) => {
  if (data && userID) {
    return api.post(`app_users/${userID}`, data, {
      cancelToken: cancelSource.token
    })
  }
}
export const deleteAppUser = (id, cancelSource) => {
  return api.delete(`app_users/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const sendSMSAppUser = (id, cancelSource) => {
  return api.post(`app_users/${id}/sms`, null, {
    cancelToken: cancelSource.token
  })
}
export const sendLoginAppUser = (id, type, cancelSource) => {
  return api.post(`app_users/${id}/send_login`, { type }, {
    cancelToken: cancelSource.token
  })
}
export const showLoginQR = (id, cancelSource) => {
  return api.get(`app_users/${id}/show_qr`, {
    cancelToken: cancelSource.token,
    responseType: 'arraybuffer'
  })
}
export const downloadLoginQR = (id, cancelSource) => {
  return api.get(`app_users/${id}/download_qr`, {
    cancelToken: cancelSource.token,
    responseType: 'arraybuffer'
  })
}
export const downloadLoginPDF = async (id) => {
  try {
    const cancelSource = CancelToken.source()
    const res = await downloadLoginQR(id, cancelSource)
    const blob = new Blob(
      [res.data],
      { type: res.headers['content-type'] }
    )
    saveAs(blob, 'qr')
  } catch (error) {
    console.warn('error', error)
  }
}
export const fetchTmpLogin = (id, cancelSource) => {
  return api.get(`app_users/${id}/show_tmp_login`, {
    cancelToken: cancelSource.token
  })
}

export const resetLogin = (id, cancelSource) => {
  return api.post(`app_users/${id}/reset_first_login`, null, {
    cancelToken: cancelSource.token
  })
}

export const sendVerifyEmail = (id, cancelSource) => {
  return api.post(`app_users/${id}/send_verify_token`, null, {
    cancelToken: cancelSource.token
  })
}

export const downloadUsersLoginFile = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, 'template.pdf')
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    delete data.file

    formData.append('data', JSON.stringify(data))
    return api.post('/app_users/download_all_qr', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const downloadCasaQrFile = async (id, data, cancelSource) => {
  return api.post(`app_users/${id}/casa_download_qr`, data, {
    cancelToken: cancelSource.token,
    responseType: 'arraybuffer'
  })
}
