import React, { useState } from 'react'
import { CancelToken } from 'axios'
import { message } from 'antd'
import {
  Card,
  DatePicker,
  Form,
  FormSubmitButton,
  PageLayout,
  Radio,
  RoleControlled,
  Row,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'

import { downloadCasaQrFile } from '../../../services'
import { casaDownloadQrSchema } from '../../../validation'
import { CASA_QR_DOWNLOAD_TEMPLATE_OPTIONS } from '../../../constants'

import DocumentDeadline from './DocumentDeadline'
import DocumentTitle from './DocumentTitle'
import DocumentContent from './DocumentContent'

function CasaDownloadQrPage ({ userID }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const breadcrumbs = [
    {
      label: 'navigation:app_users',
      link: '/app_users'
    },
    {
      label: 'navigation:app_users_download_qr',
      link: `/app_users/download_qr/${userID}`
    }
  ]

  const downloadQrFile = async (data) => {
    try {
      setLoading(true)
      const cancelSource = CancelToken.source()

      const res = await downloadCasaQrFile(userID, {
        ...data,
        date: dayjs(data?.date).format('YYYY-MM')
      }, cancelSource)

      const blob = new Blob(
        [res.data],
        { type: res.headers['content-type'] }
      )
      saveAs(blob, `qr_${userID}`)
    } catch (error) {
      if (error?.data) {
        const decoder = new TextDecoder('utf-8')
        const err = JSON.parse(decoder.decode(error?.data))
        message.error(err?.message || t('common:server_error'))
      } else {
        message.error(t('common:server_error'))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      posting={loading}
    >
      <Form
        id="downloadQrForm"
        handleSubmit={downloadQrFile}
        validationSchema={casaDownloadQrSchema}
        initialData={{
          template: null,
          date: dayjs().format('YYYY-MM'),
          title: null,
          content: null,
          deadline: null
        }}
      // authorized="custom.casa.functions.update"
      >
        <Row justify="between" >
          <div>
            <Title label="app_users:temp_login.casa.page_title" />
          </div>
          <RoleControlled authorized="app_users.functions.update">
            <FormSubmitButton formID="downloadQrForm" label="app_users:temp_login.download_qr" />
          </RoleControlled>
        </Row>

        <Card my="L">
          <Row wrap="wrap" align="baseline">
            <Radio
              name="template"
              label='app_users:temp_login.casa.template'
              options={CASA_QR_DOWNLOAD_TEMPLATE_OPTIONS}
              required
            />
          </Row>
          <Row wrap="wrap" align="baseline">
            <DocumentTitle />
          </Row>
          <Row wrap="wrap" align="baseline">
            <DocumentContent />
          </Row>
          <Row wrap="wrap" align="baseline">
            <DatePicker
              flex={1}
              name="date"
              label='app_users:temp_login.casa.date'
              required
            />
          </Row>
          <Row wrap="wrap" align="baseline">
            <DocumentDeadline />
          </Row>
        </Card>
      </Form>
    </PageLayout>
  )
}

CasaDownloadQrPage.propTypes = {
  userID: PropTypes.string
}

export default CasaDownloadQrPage
