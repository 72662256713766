import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextInput, UPDATE_FORM, useTranslation } from '@gk-devteam/apmc-core-web'

import { CASA_QR_DOWNLOAD_TEMPLATES } from '../../../constants'

export default function DocumentTitle () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const template = useSelector(state => state.form.template)

  useEffect(() => {
    let title = ''

    switch (template) {
      case CASA_QR_DOWNLOAD_TEMPLATES.EXISTING_CONTRACT:
        title = t('app_users:temp_login.casa.defaults.existing_contract.title')
        break
      case CASA_QR_DOWNLOAD_TEMPLATES.NEW_CONTRACT:
        title = t('app_users:temp_login.casa.defaults.new_contract.title')
        break
      default:
        break
    }

    dispatch({
      type: UPDATE_FORM,
      payload: {
        title
      }
    })
  }, [template, dispatch, t])

  return (
    <TextInput
      flex={1}
      name="title"
      label='app_users:temp_login.casa.title'
      maxLength={30}
      required
    />
  )
}
