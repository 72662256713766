import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextArea, UPDATE_FORM, useTranslation } from '@gk-devteam/apmc-core-web'

import { CASA_QR_DOWNLOAD_TEMPLATES } from '../../../constants'

export default function DocumentContent () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const template = useSelector(state => state.form.template)

  useEffect(() => {
    let content = ''

    switch (template) {
      case CASA_QR_DOWNLOAD_TEMPLATES.EXISTING_CONTRACT:
        content = t('app_users:temp_login.casa.defaults.existing_contract.content')
        break
      case CASA_QR_DOWNLOAD_TEMPLATES.NEW_CONTRACT:
        content = t('app_users:temp_login.casa.defaults.new_contract.content')
        break
      default:
        break
    }

    dispatch({
      type: UPDATE_FORM,
      payload: {
        content
      }
    })
  }, [template, dispatch, t])

  return (
    <TextArea
      flex={1}
      name="content"
      label='app_users:temp_login.casa.content'
      maxLength={240}
      required
    />
  )
}
