import React from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  Form,
  FormControlLabel,
  InquiryID,
  Row,
  TextArea,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import { INQUIRY_PREFIX } from '../../../constants'
import { selectLoadingYasueSurvey } from '../../../selectors'
import YasueRate from './YasueRate'

import { OptionsStyle } from './YasueSurveyForm.style'

const YasueSurveyForm = ({ data, surveyID }) => {
  const { t } = useTranslation()
  const handleSubmit = () => {}

  return (
    <Form
      id="addSurveyForm"
      handleSubmit={handleSubmit}
      loadingSelector={selectLoadingYasueSurvey}
      initialData={data}
      authorized="custom.yasue"
    >
      <Row justify="between" wrap="wrap">
        <Title text={data.contractor_name} />
      </Row>
      <Row mb="ML">
        <InquiryID id={surveyID} prefix={INQUIRY_PREFIX.YASUE.SURVEY} />
      </Row>
      <Card mb="L">
        <YasueRate rate={data.question_1} label="yasue:survey.question_1" />
        <YasueRate rate={data.question_2} label="yasue:survey.question_2" />
        <YasueRate rate={data.question_3} label="yasue:survey.question_3" />
        <YasueRate rate={data.question_4} label="yasue:survey.question_4" />
        <YasueRate rate={data.question_5} label="yasue:survey.question_5" />
        <div>
          <FormControlLabel label={t('yasue:survey.question_6')}/>
          <OptionsStyle
            name="question_6"
            options={[
              { label: 'yasue:survey.question_6_answer_1', value: 1 },
              { label: 'yasue:survey.question_6_answer_2', value: 2 },
              { label: 'yasue:survey.question_6_answer_3', value: 3 }
            ]}
            label=""
            size="L"
            flex={1}
            mb="L"
            mt="S"
            readonly
          />
        </div>
        <Row wrap="wrap" align="baseline">
          <TextArea
            name="message"
            label="yasue:survey.message"
            flex={1}
            readonly
          />
        </Row>
        <div>
          <FormControlLabel label={t('yasue:survey.question_7')}/>
          <OptionsStyle
            name="question_7"
            options={[
              { label: 'yasue:survey.question_7_answer_1', value: 1 },
              { label: 'yasue:survey.question_7_answer_2', value: 2 },
              { label: 'yasue:survey.question_7_answer_3', value: 3 },
              { label: 'yasue:survey.question_7_answer_4', value: 4 }
            ]}
            label=""
            size="L"
            flex={1}
            mb="L"
            mt="S"
            readonly
          />
        </div>
      </Card>
    </Form>
  )
}

export default YasueSurveyForm

YasueSurveyForm.propTypes = {
  data: PropTypes.object,
  surveyID: PropTypes.string
}
